<template>
    <div class="news-card p-5 flex my-5 rounded-md">
        <div class="w-2/5 pr-5">
            <p class="font-bold mb-5">{{news[0]}}</p>
            <p title="Click to read the complete article" class="text-xs mb-5 overflow-hidden line-clamp-8"
                @click="openPopup">
                {{ truncatedText }}
            </p>
            <NewsArticlePopup :showPopup="showPopup" @close-popup="closePopup" :fullText="news[14]" />
            <div class="text-xs flex">
                <div class="flex items-center tag px-3 py-1 tag rounded-xl font-bold mr-2 "><img :src="require('@/assets/img/bookmark.svg')" alt="" class="h-4 inline"> <span class="">{{news[3]}}</span></div>
                <!-- <div class="flex items-center tag px-3 py-1 tag rounded-xl font-bold mr-2 "><img :src="require('@/assets/img/bookmark.svg')" alt="" class="h-4 inline"> <span class="">Covid-19</span></div> -->
            </div>
        </div>
        <div class="w-3/5">
            <div class="flex space-x-7 mb-2">
                <!-- Red Dot -->
                <div class="flex items-center">
                    <div class="w-2 h-2  rounded-full mr-1" style="background-color: #8A2404 ;"></div><span
                        class="text-xs">Short</span>
                </div>

                <!-- Blue Dot -->
                <div class="flex items-center">
                    <div class="w-2 h-2  rounded-full mr-1" style="background-color: #F06135 ;"></div><span
                        class="text-xs">Short-Medium</span>
                </div>

                <!-- Green Dot -->
                <div class="flex items-center">
                    <div class="w-2 h-2  rounded-full mr-1" style="background-color: #F37022;"></div><span
                        class="text-xs">Medium</span>
                </div>
                <div class="flex items-center">
                    <div class="w-2 h-2  rounded-full mr-1" style="background-color: #FFB068 ;"></div><span
                        class="text-xs">Medium-Long</span>
                </div>
                <div class="flex items-center">
                    <div class="w-2 h-2  rounded-full mr-1" style="background-color: #FF985A;"></div><span
                        class="text-xs">Long</span>
                </div>
                <div class="flex items-center">
                    <div class="w-2 h-2  rounded-full mr-1" style="background-color: #FFD2A8;"></div><span
                        class="text-xs">Short-Medium-Long</span>
                </div>
            </div>

            
            <div class="flex justify-between">
                <div class="w-1/3 border rounded-md bg-white flex flex-col justify-between p-5">

                    <div v-if="news[7]>=0" class="flex items-center news-card rounded-md px-6 py-2 justify-around news-card-box">
                        <span>Price Movement</span> <img :src="priceMovementUp" class="h-5" alt="">
                    </div>
                    
                    <div v-if="news[7]<0" class="flex items-center news-card rounded-md px-6 py-2 justify-around news-card-box">
                        <span>Price Movement</span> <img :src="priceMovementDown" class="h-5" alt="">
                    </div>
                    
                    <div>
                        <p class="text-black">Sentiment Score</p>
                        <div class=" news-card rounded-md p-2">
                            <Slider :value="news[7]" @sentiment-value="handleSentiment" />
                        </div>
                    </div>
                </div>
                <div class="w-1/3 border rounded-md mx-5 bg-white">
                    <div class="p-5">
                        <p class="text-black font-bold">Period Impact</p>
                        <PieChart />
                    </div>
                </div>
                <div class="w-1/3 border rounded-md bg-white text-black">
                    <div class="p-5">
                        <p class="font-bold mb-5">Category</p>
                        <div>
                            <div class="flex items-center category-card rounded-md mb-5 p-2"> <img
                                    :src="require('@/assets/img/demand.svg')" alt="" class="inline"> <span
                                    class="ml-2">{{ news[11] }}</span></div>
                        </div>
                    </div>

                </div>
            </div>
            <p class="text-right text-xs mt-1">S&P Platts | {{ formatDate(news[1]) }} | 10:54 PM</p>
        </div>
    </div>
</template>

<script>
import PieChart from '@/components/PieChart.vue';
import Slider from '@/components/Slider.vue'
import NewsArticlePopup from '@/components/NewsArticlePopup.vue'
export default {
    components: {
        PieChart,
        Slider,
        NewsArticlePopup
    },
    data() {
        return {
            showPopup: false,
            priceMovementUp: require('@/assets/img/delta-up.svg'),
            priceMovementDown: require('@/assets/img/delta-down.svg')
        };
    },
    props: {
        news: {
            type: Object,
            required: true,
        }
    },
    computed: {
        truncatedText() {
            return this.news[14].slice(0, 650) + (this.news[14].length > 650 ? '...' : '');
        }
    },
    methods: {
        openPopup() {
            this.showPopup = true;
        },
        closePopup() {
            this.showPopup = false;
        },
        handleSentiment(value) {
            console.log(value, 'value')
            if (value >= 0) {
                this.priceMovement = require('@/assets/img/delta-up.svg')
            } else {
                this.priceMovement = require('@/assets/img/delta-down.svg')
            }
        },
        formatDate(dateString) {
            const options = { day: '2-digit', month: 'short', year: '2-digit' };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
        }
    },
}
</script>

<style scoped>
.news-card {
    background-color: #243058;
    color: white;
}

.tag {
    background-color: #C2BBBB;
    color: black;
}

.category-card {
    background-color: #E8E8E8;
}

.line-clamp-8 {
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.news-card-box {
    /* background-color: #243058; */
    /* color: white; */
    position: relative;
}

/* .news-card-main {
    position: relative;
} */

.news-card-box img {
    position: absolute;
    left: 66%;
    top: 22%;
}
</style>
