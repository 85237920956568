<template>
  <div class="split-container px-10">
    <!-- First Horizontal Split -->
    <div class="split-horizontal flex justify-between">
      <!-- Left Half with Lorem Ipsum -->
      <div class="w-1/2 mr-10 mt-10">
        <p class="font-bold text-3xl mb-2">CATALYST</p>
        <p class="text-sm">
          Catalyst represents Kearney's market-leading capabilities within Energy and Process Industries packaged as a single platform.
          It encompasses a range of solutions crafted by Kearney to streamline and enhance the decision-making
          processes within the industry value chain. These encompass a comprehensive suite of optimization recommendations
          and predictive insights that are indispensable to operators, maintenance crews, sales managers, and various
          other stakeholders, facilitating the ability to make well-informed, data-driven decisions.
        </p>
      </div>
      <!-- Right Half with an Image -->
      <div class="w-1/2">
        <img :src="images['homeImage']" alt="Image" class="image w-full rounded-b-lg" />
      </div>
    </div>

    <div class="sets-container p-5">
      <div class="flex items-center justify-between">
        <button @click="showPreviousSet"><img :src="icons['caretLeft']" alt="Image" class="caret" /></button>
        <div v-for="(set, index) in sets" :key="index" style="width: 95%;"
          :class="['rounded-lg', `set${index + 1}`, { active: index === activeSetIndex }]">
          <CarouselRow :items="items[index]" class="cards-row" @sub-option-selected="handleRouterLinkClick" />
        </div>
        <button @click="showNextSet"><img :src="icons['caretRight']" alt="Image" class="caret" /></button>
      </div>
    </div>

  </div>
</template>

<script>
import CarouselRow from './CarouselRow.vue';
export default {
  name: 'Home',
  components: {
    CarouselRow
  },
  data() {
    return {
      sets: [1, 2, 3, 4, 5],
      activeSetIndex: 0,
      icons: {
        caretLeft: require('@/assets/img/left-caret.svg'),
        caretRight: require('@/assets/img/right-caret.svg'),
      },
      images: {
        homeImage: require('@/assets/img/home-image.png'),
      },

      items: [
        [
          { title: 'YIELDify - Yield Optimizer', id: 1, description: 'State-of-the-art analytical tool that maximizes refinery unit output by intelligently adjusting controllable parameters using real-time pricing data and advanced AI/ML models providing refinery operators with recommendations on feed processing inputs.' },
          { title: 'Deep Thermal - Combustion & SADC Optimizer', id: 2, description: 'Data-driven guidance tool for thermal power producers, optimizing operational parameters like air-to-fuel ratio, temperature setpoints, and secondary air damper adjustments for boilers, improving operational efficiency based on historical performance and coal conditions.' },
        ],
        [

          { title: 'Smart Soot-Blower', id: 3, description: 'Advanced optimization model that helps power plant operators optimize boiler soot cleaning schedules by considering factors like coal feed rates, cleaning history, equipment usage, and current temperatures. This proactive approach helps create cost-efficient soot-blowing schedules.' },
          { title: 'Predictive Maintenance', id: 4, description: 'AI/ML-based predictive maintenance tool for detecting anomalies in the process parameters of renewable units such as Solar Inverters & Wind turbine Gearboxes by leveraging monitored conditions of multiple assets. The solution provides scope for controlling processes beforehand and avoiding abrupt shutdowns.' }
        ],
        [
          { title: 'Commodity Pathfinder', id: 5, description: 'A comprehensive solution for all commodity requirements: - prices, forecasts, directional news guidance, and report synthesis all in one place to identify risks and opportunities in time to manage uncertainty.' },
          { title: 'Raffia - Commodity Finger Printer', id: 6, description: 'Advanced AI/ML-based forward-looking pricing tool for predicting petrochemical commodity, i.e., Raffia prices over the upcoming weeks. The tool is capable of predicting prices at different time points in the future. The tool leverages multiple price influencing factors such as supply & demand side, inventory levels at customers, competitors intelligence, etc.' }
        ],
        [
          { title: 'Prescriptive  Capex', id: 7, description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima alias voluptate totam consequuntur sed dolor autem repudiandae asperiores? Eveniet accusantium architecto quo voluptatem tempora tenetur ex, impedit consequuntur numquam sapiente!' },
          { title: 'SenSA - Sentiment Scoring Analysis', id: 8, description: 'Natural Language Processing (NLP) based sentiment analysis solution for reviewing publicly available customer reviews in an automated manner. The tool can be leveraged for internal benchmarking as well as for competitor  benchmarking of retail outlets.' }
        ],
        [
          { title: 'Heat Exchanger Cleaning Scheduler', id: 9, description: 'An advanced heat exchanger system optimizes refinery efficiency by mitigating fouling through predictive maintenance. Real-time monitoring and AI-driven models forecast fouling behavior, enabling cost-effective cleaning schedules and maintaining optimal heat transfer performance.' },
        ]
      ]
    };
  },
  methods: {
    handleRouterLinkClick(subOptionId) {
      this.$emit("sub-option-selected", subOptionId);
    },
    showNextSet() {
      this.activeSetIndex++;
      if (this.activeSetIndex >= this.sets.length) {
        this.activeSetIndex = 0;
      }
    },

    showNextSet() {
      this.activeSetIndex++;
    if (this.activeSetIndex >= this.sets.length) {
      this.activeSetIndex = 0;
    }
    },

    showPreviousSet() {
      this.activeSetIndex--;
    if (this.activeSetIndex < 0) {
      this.activeSetIndex = this.sets.length - 1;
    }
    },
  }
};
</script>

<style scoped>
.image {
  height: 250px;
  margin-bottom: 3rem;
}

.sets-container {
  background-color: rgb(243, 244, 246);
  border-radius: 10px;
}

.caret{
  height: 3rem;
}

.sets-container .rounded-lg {
  display: none;
  animation: slideInRight 0.1s ease-in-out forwards;
}

/* Show the active set */
.sets-container .rounded-lg.active {
  display: block;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    /* Start from the right of the screen */
  }

  to {
    transform: translateX(0);
    /* Move to its original position */
  }
}

/* Define the keyframes for the slide-out animation */
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    /* Start from its original position */
  }

  to {
    transform: translateX(-100%);
    /* Move to the left of the screen */
  }
}
</style>