<template>
    <div class="flex flex-col overflow-y-auto px-5 pb-3 pt-10 shadow">
        <Navbar />
        <!-- <div class="flex justify-between"> -->
            <div class="text-left px-20">

                <div class="flex items-center justify-between py-5">
                    <!-- Left side content -->
                    <p><strong>Pulse</strong> - Sentiment Analysis</p>

                    <!-- Right side content -->
                    <div class="relative">
                    <select v-model="selectedCommodity" @change="handleSelectChange" class="block appearance-none bg-gray-200 border border-gray-400 text-gray-800 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        <option disabled value="">Select Commodity</option>
                        <option v-for="commodity in commodities" :key="commodity" :value="commodity">{{ commodity }}</option>
                    </select>
                    </div>
                </div>
                
                <label for="datepicker">Filter by: </label>
                <input @change="handleDateSelectSentiment($event.target.value)" type="date" id="datepicker" name="datepicker"
                            class="border p-1 border-gray-300 rounded-md focus:outline-none bg-gray-200 focus:ring focus:border-blue-500 mb-2" :value="sentimentCardDate" />

                <div class="flex items-center justify-between">
                    <SentimentCard class="mr-10" :sortedData="dailyWeeklyData.dailySortedData?.data" :title="'Daily Sentiment'" />
                    <SentimentCard :sortedData="dailyWeeklyData.weeklySortedData?.data" :title="'Weekly Sentiment'" />
                </div>
            </div>
        <!-- </div> -->
        <div class="px-20">
            <Headline :breakingNews="breakingNewsData"/>
        </div>  
        <div class="text-left px-20">
            <p class="pt-5 pb-2 border-b mb-2"><strong>Sentiment Snapshot</strong></p>
            <div class="flex justify-between mt-5">
                <div class="w-1/4 ">

                    <div class="mr-5">
                        <label for="datepicker">Filter by: </label>
                        <input @change="handleDateSelectSnapshot($event.target.value)" type="date" id="datepicker" name="datepicker"
                            class="border p-1 border-gray-300 rounded-md focus:outline-none bg-gray-200 focus:ring focus:border-blue-500" :value="currentDate" />
                            <p class="font-bold text-xs mt-5">Aggregated Sentiments</p>
                        <highcharts :options="chartOptions" />
                        <div class="flex space-x-4 pl-2">
                            <div class="flex items-center">
                                <div class="w-2 h-2 bg-red-500 rounded-full mr-1"></div><span class="text-xs">Negative</span>
                            </div>

                            <div class="flex items-center">
                                <div class="w-2 h-2 bg-gray-500 rounded-full mr-1"></div><span class="text-xs">Neutral</span>
                            </div>

                            <div class="flex items-center">
                                <div class="w-2 h-2 bg-green-500 rounded-full mr-1"></div><span class="text-xs">Positive</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-3/4 flex border-l">
                    <div class="w-1/4 mr-5 flex flex-col pl-10">
                        <div>
                            <input :value="fromDate" @change="handleDateSelectTrendsFrom($event.target.value)" type="date" id="datepicker" name="datepicker"
                                class="border p-1 w-full float-right border-gray-300 bg-gray-200 rounded-md focus:outline-none focus:ring focus:border-blue-500" />
                        </div>
                        <p class="my-2">To</p>
                        <div>
                            <input :value="currentDate" @change="handleDateSelectTrendsTo($event.target.value)" type="date" id="datepicker" name="datepicker"
                                class="border p-1 w-full float-right border-gray-300 bg-gray-200 rounded-md focus:outline-none focus:ring focus:border-blue-500" />
                        </div>
                    </div>
                    <highcharts :options="options" ref="chart" class="w-3/4"></highcharts>
                </div>
            </div>
        </div>
        <div class="text-left px-20">
            <p class="py-5 pb-2 border-b mb-2"><strong>News Stories</strong></p>
            <Dropdown @search="handleSearch" @category-filter="handleCategoryFilter" @sub-category-filter="handleSubCategoryFilter" />
            <div class="">
                <NewsCard v-for="(story, index) in paginatedNews" :key="index" :news="story" />
            </div>
        </div>
        <div class="pagination-controls mb-4 pb-12">
            <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
    </div>
</template>

<script>
import SentimentCard from '@/components/SentimentCard.vue';
import Navbar from '@/components/Navbar.vue';
import NewsCard from '@/components/NewsCard.vue';
import Dropdown from '@/components/Dropdown.vue';
import Headline from '@/components/Headline.vue';
import { mapState, mapActions } from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import Highcharts from 'highcharts';

export default {
    components: {
        SentimentCard,
        Navbar,
        NewsCard,
        Dropdown,
        Headline
    },
    data() {
        return {
            selectedCommodity: 'Steel', // Initial placeholder text
            commodities: ['Steel', 'Brent', 'Naphtha'],
            chartOptions: {
                chart: {
                type: 'bar',
                height: 100
                },
                title: {
                text: undefined
                },
                xAxis: {
                visible: false,
                categories: []
                },
                yAxis: {
                visible: false,
                min: 0,
                max: 100,
                title: {
                    text: 'Sentiments'
                }
                },
                legend: {
                enabled: false
                },
                plotOptions: {
                series: {
                    stacking: 'normal',
                    dataLabels: {
                    enabled: true,
                    format: '{y:.2f}%' // Display values up to two decimal places
                    }
                }
                },
                series: [
                {
                    name: 'Positive',
                    data: []
                },
                {
                    name: 'Neutral',
                    data: []
                },
                {
                    name: 'Negative',
                    data: []
                }
                ]
            },
            donutOptions: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false
                },
                title: {
                    text: 'Browser<br>shares<br>January<br>2022',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 60
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                            distance: -50,
                            style: {
                                fontWeight: 'bold',
                                color: 'white'
                            }
                        },
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '75%'],
                        size: '110%'
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                        ['Chrome', 73.86],
                        ['Edge', 11.97],
                        ['Firefox', 5.52],
                        ['Safari', 2.98],
                        ['Internet Explorer', 1.90],
                        ['Other', 3.77]
                    ]
                }]
            },
            options: {
                chart: {
                    type: "spline",
                    height: 350,
                    animation: {
                        duration: 1000 // Custom entrance animation duration in milliseconds
                    }
                },
                title: {
                    text: 'Sentiments Trend'
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: "Dates"
                    },
                    labels: {
                        format: '{value:%m-%d-%Y}'
                    }
                },
                yAxis: {
                    title: {
                        text: "Sentiment %"
                    },
                    min: 0, // Minimum value for the y-axis
                    max: 100, // Maximum value for the y-axis
                },
                series: [
                    {
                        name: "Positive",
                        data: [],
                        color: '#9CC6A0'
                    },
                    {
                        name: "Neutral",
                        data: [],
                        color: '#C4C4C4'
                    },
                    {
                        name: "Negative",
                        data: [],
                        color: '#FF8080'
                    }
                ],
                tooltip: {
                    formatter: function() {
                        var tooltip = '<span style="font-size: 10px">' + Highcharts.dateFormat('%m-%d-%Y', this.x) + '</span><br/>';
                        this.points.forEach(function(point) {
                            tooltip += '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + point.y.toFixed(2) + '%</b><br/>';
                        });
                        return tooltip;
                    },
                    shared: true
                },
            },
            currentPage: 1,
            pageSize: 10,
            filteredNews: [],
            selectTrendsFrom: '',
            selectTrendsTo: '',
            currentDate: '',
            fromDate: '',
            sentimentCardDate: '',
        }
    },
    computed: {
        ...mapState('sheets', [
            'articleWiseOutput',
            'dailySummaryAndSentiment',
            'weeklySummaryAndSentiment'
        ]),
        dailyWeeklyData() {
            let dailySummary = this.dailySummaryAndSentiment || [];
            dailySummary = dailySummary.slice(1); // Remove the header row

            let dailyData = dailySummary.map(entry => ({ type: 'daily', data: entry }));
            
            dailyData = dailyData.filter(entry => {
                if (this.sentimentCardDate !== '') {
                    return entry.data[7] === this.selectedCommodity && new Date(entry.data[0]) <= new Date(this.sentimentCardDate)
                } else {
                    return entry.data[7] === this.selectedCommodity
                }
            });
            const dailySortedData = dailyData.sort((a, b) => new Date(b[0]) - new Date(a[0]));
            const latestDailyData = dailySortedData.slice(0, 1);
            
            let weeklySummary = this.weeklySummaryAndSentiment || [];
            weeklySummary = weeklySummary.slice(1); // Remove the header row

            let weeklyData = weeklySummary.map(entry => ({ type: 'weekly', data: entry }));
            weeklyData = weeklyData.filter(entry =>  {
                if (this.sentimentCardDate !== '') {
                    return entry.data && entry.data[8] === this.selectedCommodity && new Date(entry.data[0]) <= new Date(this.sentimentCardDate)
                } else {
                    return entry.data && entry.data[8] === this.selectedCommodity
                }
            });

            const weeklySortedData = weeklyData.sort((a, b) => new Date(b[0]) - new Date(a[0]));
            const latestWeeklyData = weeklySortedData.slice(0, 1);
            return { dailySortedData: latestDailyData[0], weeklySortedData: latestWeeklyData[0] };
        },
        newsStories() {
            let articleData = this.articleWiseOutput || [];
            articleData = articleData ? articleData.slice(2) : articleData; // Remove the header row
            console.log(articleData, 'articleData articleData90')
            if (articleData.length === 0) {
                return [];
            }
            const sortedData = articleData.sort((a, b) => new Date(b[5]) - new Date(a[5]));
            const finalData = sortedData.filter(item => {
                return item[17] == this.selectedCommodity
            });
            console.log(finalData, 'finalData finalData')
            return finalData;
        },
        paginatedNews() {
            this.newsStories

            const startIndex = (this.currentPage - 1) * this.pageSize;
            const endIndex = startIndex + this.pageSize;
            console.log(this.filteredNews.length, 'filteredNew s toype')
            return this.filteredNews.slice(startIndex, endIndex);
        },
        totalPages() {
            this.newsStories
            return Math.ceil(this.filteredNews.length / this.pageSize);
        },
        breakingNewsData() {
            let dailySummary = this.dailySummaryAndSentiment || [];
            dailySummary = dailySummary ? dailySummary.slice(1) : dailySummary; // Remove the header row

            if (dailySummary.length === 0) {
                return [];
            }   
            const sortedData = dailySummary.sort((a, b) => new Date(b[0]) - new Date(a[0]));
            console.log(sortedData.slice(0, 3), 'sortedData.slice(0, 3)')
            return sortedData.slice(0, 1);
        },
        totalPages() {
            this.newsStories
            return Math.ceil(this.filteredNews.length / this.pageSize);
        }
    },
    methods: {
        handleDateSelectSentiment(selectedDate) {
            this.sentimentCardDate = selectedDate;
        },
        handleSelectChange(event) {
            // Update selectedCommodity with the value from the event
            this.selectedCommodity = event.target.value;
            console.log('Selected commodity:', this.selectedCommodity);
            
            const latestDate = this.latestDateFromSheet(this.selectedCommodity);

            if (latestDate) {
                this.currentDate = latestDate;
                this.fromDate = this.subtractDays(latestDate, 14);
                this.fromDate = this.formatDate(this.fromDate);
                this.currentDate = this.formatDate(this.currentDate);
                console.log(this.fromDate, this.currentDate, 'formatted date');

                this.processData(this.fromDate, this.currentDate);
                this.aggregatedSentiments(this.currentDate);
                this.filteredNews = this.newsStories;
                // You can emit an event or perform other actions based on the selected commodity
            } else {
                console.error('Failed to get the latest date');
            }
        },
        ...mapActions('sheets', [
            'fetchAndStoreArticleWiseOutputData',
            'fetchAndStoreDailySummaryAndSentimentOutputData',
            'fetchAndStoreWeeklySummaryAndSentimentOutputData'
        ]),
        truncateString(str, maxLength = 50) {
            if (str.length <= maxLength) {
                return str;
            } else {
                return str.substring(0, maxLength) + '...';
            }
        },
        processData(startDate, endDate) {
            const dailyData = {};

            let articleData = this.dailySummaryAndSentiment || [];
            articleData = articleData ? articleData.slice(1) : articleData; // Remove the header row
            articleData.forEach(item => {
                const date = new Date(item[0]);
                // Check if the date is within the specified range
                if (date >= new Date(startDate) && date <= new Date(endDate) && item[7] == this.selectedCommodity) {
                    const formattedDate = date.toISOString().split('T')[0];
                    if (!dailyData[formattedDate]) {
                        dailyData[formattedDate] = { positive: 0, neutral: 0, negative: 0 };
                    }
                    dailyData[formattedDate].positive = parseFloat(item[11]);
                    dailyData[formattedDate].neutral = parseFloat(item[13]);
                    dailyData[formattedDate].negative = parseFloat(item[12]);
                }
            });

            const categories = [];
            const positiveData = [];
            const neutralData = [];
            const negativeData = [];
            
            for (const date in dailyData) {
                categories.push(date);
                positiveData.push(dailyData[date].positive);
                neutralData.push(dailyData[date].neutral);
                negativeData.push(dailyData[date].negative);
            }

            // Ensure categories (dates) are sorted
            categories.sort((a, b) => new Date(a) - new Date(b));

            this.options.xAxis.categories = categories;
            this.options.series[0].data = positiveData;
            this.options.series[1].data = neutralData;
            this.options.series[2].data = negativeData;
        },
        aggregatedSentiments(cutoffDate) {
            let data = this.dailySummaryAndSentiment || [];
            data = data ? data.slice(1) : data; // Remove the header row

            const cutoff = new Date(cutoffDate);
            const filteredData = data.filter(item => new Date(item[0]) <= cutoff && item[7] == this.selectedCommodity);


            const categories = Array.from(new Set(filteredData.map(item => {
                const date = new Date(item[0]);
                return date.getFullYear().toString();
            })));

            const aggregatedData = { positive: 0, neutral: 0, negative: 0 };
            let positiveCount = 0;
            let neutralCount = 0;
            let negativeCount = 0;

            filteredData.forEach(item => {
                if (item[2] === 'Positive') {
                    aggregatedData.positive += item[1];
                    positiveCount += 1;
                } else if (item[2] === 'Neutral') {
                    aggregatedData.neutral += item[1];
                    neutralCount += 1;
                } else if (item[2] === 'Negative') {
                    aggregatedData.negative += item[1];
                    negativeCount += 1;
                }
            });

            // Normalize scores to ensure they are within 0-100%
            const totalSentiments = positiveCount + neutralCount + negativeCount;
            if (totalSentiments > 0) {
                aggregatedData.positive = (positiveCount / totalSentiments) * 100;
                aggregatedData.neutral = (neutralCount / totalSentiments) * 100;
                aggregatedData.negative = (negativeCount / totalSentiments) * 100;
            }

            // Format values to two decimal places
            aggregatedData.positive = aggregatedData.positive.toFixed(2);
            aggregatedData.neutral = aggregatedData.neutral.toFixed(2);
            aggregatedData.negative = aggregatedData.negative.toFixed(2);

            this.chartOptions.xAxis.categories = categories;
            this.chartOptions.series[0].data = [{ y: parseFloat(aggregatedData.positive), color: '#9CC6A0' }];
            this.chartOptions.series[1].data = [{ y: parseFloat(aggregatedData.neutral), color: '#C4C4C4' }];
            this.chartOptions.series[2].data = [{ y: parseFloat(aggregatedData.negative), color: '#FF8080' }];
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        handleSearch(searchQuery) {
            console.log(this.filteredNews, 'filteredNews')
            if (searchQuery === '') {
                this.filteredNews = this.newsStories;
                return;
            }

            if (this.filteredNews.length === 0) {
                return;
            }
            
            this.filteredNews = this.newsStories.filter(story =>
                story[0] && story[0].toLowerCase().includes(searchQuery.toLowerCase())
            );
            console.log(this.filteredNews.length, 'filteredNews length')
            
            this.currentPage = 1;
        },
        handleCategoryFilter(category) {
            console.log(category, 'category')
            if (category === '') {
                this.filteredNews = this.newsStories;
                return;
            }

            console.log(this.filteredNews, 'filteredNews category')
            console.log(this.newsStories, 'this.newsStories category')
            
            this.filteredNews = this.newsStories.filter(story =>
                story[10] && story[10].toLowerCase().includes(category.toLowerCase())
            );
            console.log(this.filteredNews.length, 'filteredNews category')
            
            this.currentPage = 1;
        },
        handleSubCategoryFilter(subCategory) {
            console.log(subCategory, 'subCategory')
            if (subCategory === '') {
                this.filteredNews = this.newsStories;
                return;
            }

            this.filteredNews = this.newsStories.filter(story =>
                story[11] && story[11].toLowerCase().includes(subCategory.toLowerCase())
            );
            console.log(this.filteredNews.length, 'filteredNews subCategory')
            
            this.currentPage = 1;
        },
        handleDateSelectSnapshot(selectedDate) {
            // Do something with the selected date
            console.log("Selected Date:", selectedDate);
            const inputDate = new Date(selectedDate);

            const formattedDate = `${inputDate.getMonth() + 1}/${inputDate.getDate()}/${inputDate.getFullYear()}`;

            this.aggregatedSentiments(formattedDate );
        },
        handleDateSelectTrendsFrom(selectedDate) {
            console.log("Selected Date:", selectedDate);
            const inputDate = new Date(selectedDate);

            const formattedDate = `${inputDate.getMonth() + 1}/${inputDate.getDate()}/${inputDate.getFullYear()}`;
            
            if (this.selectTrendsTo !== '') {
                                this.processData(selectedDate, formattedDate);
            }
            this.selectTrendsFrom = formattedDate;
        },
        handleDateSelectTrendsTo(selectedDate) {
            console.log("Selected Date:", selectedDate);
            const inputDate = new Date(selectedDate);

            const formattedDate = `${inputDate.getMonth() + 1}/${inputDate.getDate()}/${inputDate.getFullYear()}`;
            
            if (this.selectTrendsFrom !== '') {
                this.processData(this.selectTrendsFrom, formattedDate);
            }
            this.selectTrendsTo = formattedDate;
        },
        latestDateFromSheet(commodity) {       
            const getLatestDate = (data) => {
                const dates = data.map(row => new Date(row[0]));

                if (dates.length === 0 || isNaN(dates[0].getTime())) {
                    console.error('No valid dates found');
                    return null;
                }

                const latestDate = new Date(Math.max.apply(null, dates));
                return latestDate;
            };

            let dailySentiments = this.dailySummaryAndSentiment || [];
            dailySentiments = dailySentiments.slice(1);

            const commodityBasedSentiments = dailySentiments.filter(row => row[7] === commodity);

            const latestDate = getLatestDate(commodityBasedSentiments);

            return latestDate
        },
        formatDate(date) {
            console.log(date, 'opoppop')
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}-${month}-${day}`;
        },
        subtractDays(date, days) {
            const d = new Date(date);
            d.setDate(d.getDate() - days);
            return d;
        }
    },
    mounted() {
        this.fetchAndStoreArticleWiseOutputData();
        this.fetchAndStoreDailySummaryAndSentimentOutputData();
        this.fetchAndStoreWeeklySummaryAndSentimentOutputData();
        this.processData(this.fromDate, this.currentDate);
        this.aggregatedSentiments(this.currentDate);
        this.filteredNews = this.newsStories;
        console.log(this.filteredNews, 'filteredNews')
    },
    created() {
        // Get the current date
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(today.getDate()).padStart(2, '0');

        // Format the date as YYYY-MM-DD
        this.currentDate = `${year}-${month}-${day}`;

        // Get the date two weeks in the past
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - 14);
        const pastYear = pastDate.getFullYear();
        const pastMonth = String(pastDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const pastDay = String(pastDate.getDate()).padStart(2, '0');

        // Format the past date as YYYY-MM-DD
        this.fromDate = `${pastYear}-${pastMonth}-${pastDay}`;

        const latestDate = this.latestDateFromSheet('Steel');

        if (latestDate) {
            this.currentDate = latestDate;
            this.sentimentCardDate = this.formatDate(latestDate);
            this.fromDate = this.subtractDays(latestDate, 14);
            this.fromDate = this.formatDate(this.fromDate);
            this.currentDate = this.formatDate(this.currentDate);
            console.log(this.fromDate, this.currentDate, 'formatted date');
        } else {
            console.error('Failed to get the latest date');
        }
    }
}
</script>

<style scoped>
.aggregated-sentiment {
    background-color: #243058;
    color: white;
}
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.pagination-controls button {
  padding: 2px 10px;
  background-color: #F37022;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.pagination-controls button:disabled {
  background-color: #FFDBC6;
  cursor: not-allowed;
}
</style>