<template>
    <highcharts :options="donutOptions" />
</template>


<script>
export default {
    data() {
        return {
            donutOptions: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    height: 150
                },
                title: {
                    text: 'Medium Term',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 60,
                    style: {
                        fontSize: '0.8rem'
                    }
                },
                tooltip: {
                    pointFormat: ''
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: false,
                            distance: -50,
                            style: {
                                fontWeight: 'bold',
                                color: 'white'
                            }
                        },
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '75%'],
                        size: '110%',
                        colors: ['#8A2404', '#F06135', '#F37022', '#FFB068', '#FF985A', '#FFD2A8'],
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                        ['Short', 30.00],
                        ['Short-Medium', 30.00],
                        ['Medium', 30.00],
                        ['Medium-Long', 30.00],
                        ['Long', 30.00],
                        ['Short-Medium-Long', 30.00]
                    ]
                }]
            }
        }
    }
}
</script>