import * as XLSX from 'xlsx';
import Papa from 'papaparse';

export default {
  namespaced: true,
  state: {
    sheets: {},
    selectedCommodityTile: 'Steel',
    articleWiseOutput: [],
    dailySummaryAndSentiment: [],
    weeklySummaryAndSentiment: [],
    priceProjections: [],
    organizedBucketPredictions: [],
    predictiveMaintenanceData: {}
  },
  mutations: {
    setSheetData(state, { sheetName, data }) {
      state.sheets = {
        ...state.sheets,
        [sheetName]: data,
      };
    },
    setPredicitveMaintenanceSheetData(state, { sheetName, data }) {
      state.predictiveMaintenanceData = {
          ...state.predictiveMaintenanceData,
          [sheetName]: data,
      };
    },
    setArticleWiseData(state, data) {
      state.articleWiseOutput = data;
    },
    setDailySummaryAndSentiment(state, data) {
      state.dailySummaryAndSentiment = data;
    },
    setWeeklySummaryAndSentiment(state, data) {
      state.weeklySummaryAndSentiment = data;
    },
    updateSelectedCommodityTile(state, commodity) {
      state.selectedCommodityTile = commodity;
    },
    setPriceProjections(state, data) {
      state.priceProjections = data;
    },
    setOrganizedBucketPredictions(state, data) {
      state.organizedBucketPredictions = data;
    }
  },
  actions: {
    fetchAndStoreArticleWiseOutputData({ commit }) {
      fetch('/article-wise-output.csv')
        .then(response => response.text())
        .then(csv => {
          const jsonData = Papa.parse(csv, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          }).data;
          const arrayData = jsonData.map(row => Object.values(row)); // Convert to array of arrays format
          commit('setArticleWiseData', arrayData);
        })
        .catch(error => {
          console.error('Error fetching article-wise output data:', error);
        });
    },
    fetchAndStoreDailySummaryAndSentimentOutputData({ commit }) {
      fetch('/daily-summary-and-sentiment.xlsx')
        .then(response => response.arrayBuffer())
        .then(data => {
          const readOpts = {
            cellText: false,
            cellDates: true,
            type: 'array'
          };

          const jsonOpts = {
            header: 1,
            defval: '',
            blankrows: true,
            raw: false,
            dateNF: 'm"/"d"/"yyyy' // Ensure date format is handled correctly
          };

          const workbook = XLSX.read(data, readOpts);
          const worksheet = workbook.Sheets['Sheet1'];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, jsonOpts);

          commit('setArticleWiseData', jsonData);
        })
        .catch(error => {
          console.error('Error fetching article-wise output data:', error);
        });
    },
    fetchAndStoreDailySummaryAndSentimentOutputData({ commit }) {
      fetch('/daily-summary-and-sentiment.xlsx')
        .then(response => response.arrayBuffer())
        .then(data => {
          const readOpts = {
            cellText: false,
            cellDates: true,
            type: 'array'
          };
          console.log(data, 'line66');
          const jsonOpts = {
            header: 1,
            defval: '',
            blankrows: true,
            raw: false,
            dateNF: 'm"/"d"/"yyyy' // Ensure date format is handled correctly
          };

          const workbook = XLSX.read(data, readOpts);
          const worksheet = workbook.Sheets['Sheet1'];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, jsonOpts);

          commit('setDailySummaryAndSentiment', jsonData);
        })
        .catch(error => {
          console.error('Error fetching daily summary and sentiment data:', error);
        });
    },
    fetchAndStoreWeeklySummaryAndSentimentOutputData({ commit }) {
      fetch('/weekly-summary-and-sentiment.xlsx')
        .then(response => response.arrayBuffer())
        .then(data => {
          const readOpts = {
            cellText: false,
            cellDates: true,
            type: 'array'
          };
          console.log(data, 'line66');
          const jsonOpts = {
            header: 1,
            defval: '',
            blankrows: true,
            raw: false,
            dateNF: 'm"/"d"/"yyyy' // Ensure date format is handled correctly
          };

          const workbook = XLSX.read(data, readOpts);
          const worksheet = workbook.Sheets['Sheet1'];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, jsonOpts);
          console.log(jsonData, 'line75')

          commit('setWeeklySummaryAndSentiment', jsonData);
        })
        .catch(error => {
          console.error('Error fetching daily summary and sentiment data:', error);
        });
    },
    fetchAndStorePriceProjectionsData({ commit }) {
      fetch('/price-projections.xlsx')
        .then(response => response.arrayBuffer())
        .then(data => {
          const readOpts = {
            cellText: false,
            cellDates: true,
            type: 'array'
          };

          const jsonOpts = {
            header: 1,
            defval: '',
            blankrows: true,
            raw: false,
            dateNF: 'm"/"d"/"yyyy' // Ensure date format is handled correctly
          };

          const workbook = XLSX.read(data, readOpts);
          const worksheet = workbook.Sheets['Sheet1'];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, jsonOpts);

          commit('setPriceProjections', jsonData);
        }) 
        .catch(error => {
          console.error('Error fetching price projections data:', error);
        }
      );
    },
    fetchAndStoreOrganizedBucketPredictionsData({ commit }) {
      fetch('/organized-bucket-predictions.xlsx')
        .then(response => response.arrayBuffer())
        .then(data => {
          const readOpts = {
            cellText: false,
            cellDates: true,
            type: 'array'
          };

          const jsonOpts = {
            header: 1,
            defval: '',
            blankrows: true,
            raw: false,
            dateNF: 'm"/"d"/"yyyy' // Ensure date format is handled correctly
          };

          const workbook = XLSX.read(data, readOpts);
          const worksheet = workbook.Sheets['Sheet1'];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, jsonOpts);

          commit('setOrganizedBucketPredictions', jsonData);
        })
        .catch(error => {
          console.error('Error fetching organized bucket predictions data:', error);
        });
    },
    fetchAndStoreSheetData({ commit }) {
      fetch('/News Sentiment Analyzer DUMMY data.xlsx')
        .then(response => response.arrayBuffer())
        .then(data => {
          const readOpts = {
            cellText: false,
            cellDates: true,
            type: 'array'
          };

          const jsonOpts = {
            header: 1,
            defval: '',
            blankrows: true,
            raw: false,
            dateNF: 'm"/"d"/"yyyy' // Ensure date format is handled correctly
          };

          const workbook = XLSX.read(data, readOpts);
          workbook.SheetNames.forEach(sheetName => {
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, jsonOpts);
            commit('setSheetData', { sheetName, data: jsonData });
          });
        })
        .catch(error => {
          console.error('Error fetching sheet data:', error);
        });
    },
    setSelectedCommodityTile({ commit }, commodity) {
      commit('updateSelectedCommodityTile', commodity);
    },
    fetchAndStorePredictiveMaintenanceData({ commit }) {
      fetch('/predictive_maintenance.xlsx')
          .then(response => response.arrayBuffer())
          .then(data => {
              const readOpts = {
                  cellText: false,
                  cellDates: true,
                  type: 'array'
              };

              const jsonOpts = {
                  header: 1,
                  defval: '',
                  blankrows: true,
                  raw: true,
              };

              const workbook = XLSX.read(data, readOpts);
              workbook.SheetNames.forEach(sheetName => {
                  const worksheet = workbook.Sheets[sheetName];
                  const jsonData = XLSX.utils.sheet_to_json(worksheet, jsonOpts);
                  console.log(jsonData, '260')
                  commit('setPredicitveMaintenanceSheetData', { sheetName, data: jsonData });
              });

          })
          .catch(error => {
              console.error('Error fetching Predictive Maintenance Data:', error);
          });
    }
  },
};
