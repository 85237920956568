<template>
  <header class="bg-black text-white flex items-center px-4 py-2 rounded-br-lg">
    <!-- Logo and vertical line -->
    <div class="flex items-center">
      <button @click="$emit('toggle-sideNav')" class="mr-3"><img
          :src="sideNavOpen ? icons['closeIcon'] : icons['hamBurgerIcon']" alt=""
          style="width: 29px; height: 20px;"></button>
      <div class="w-36 h-12 mr-2">
        <!-- Replace 'your-logo.svg' with the actual path to your SVG file -->
        <img src="@/assets/img/Kearney_Logo.svg" class="w-36 h-12 ml-5" alt="Kearney Logo" />
      </div>
      <div class="absolute h-8 border-l border-white" style="left: 20%;"></div>
    </div>

    <!-- Admin text -->
    <div class="absolute text-sm" style="left: 22%;">CATALYST</div>

    <!-- Links -->
    <div class="absolute w-1/5 flex items-center justify-end" style="right: 2%;">
      <!-- Home logo -->
      <a href="#" @click="$emit('home')" class="flex items-center text-white group relative">
        <img :src="icons['homeIcon']" alt="" class="inline-block mr-1 h-3" />
        <span class="inline-block align-middle">Home</span>
        <div class="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-purple-800 to-purple-400"></div>
      </a>
      <button @click="dropdownOpen = !dropdownOpen"
        class="flex mx-5 items-center space-x-1 text-white focus:outline-none">
        <img class="rounded-full mr-2" style="height: 2rem; width: 2rem"
        :src="icons['userIcon']" />
        <div class="mr-1">
          <p class="text-left text-xs">{{ name }}</p>
          <p style="font-size: 0.6rem" class="text-left">Kearney User</p>
        </div>
        <img :src="icons['dropdownIcon']" alt="" class="h-1" />
      </button>
      <div v-show="dropdownOpen" class="absolute right-0 mt-2 py-2 w-36 bg-white border rounded-b-lg shadow-lg"
        style="top: 100%; right: 0;" @click.stop>
        <a href="#" @click="$emit('logout')" class="block px-4 py-2 text-gray-800 hover:bg-gray-200">Logout</a>
      </div>

    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      dropdownOpen: false,
      icons: {
        bellIcon: require("@/assets/img/bell-icon.svg"),
        dropdownIcon: require("@/assets/img/dropdown-icon.svg"),
        hamBurgerIcon: require("@/assets/img/hamburger-icon.svg"),
        closeIcon: require("@/assets/img/close-icon.svg"),
        homeIcon: require("@/assets/img/Home.svg"),
        userIcon: require("@/assets/img/user.svg")
      },
    };
  },
  props: {
    name: {
      type: String,
      default: 'John Doe'
    },
    sideNavOpen: Boolean
  }
};
</script>

<style>/* Add your styling here */</style>
