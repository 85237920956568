<template>
    <div class="border p-2 flex justify-between items-center card rounded-md">
        <div>
            <p>{{ title }}</p>
            <p class="k-text-orange" v-if="commodity == 'Steel'">£{{ value }}/mt</p>
            <p class="k-text-orange" v-if="commodity == 'Brent'">${{ value }}/bbl</p>
            <p class="k-text-orange" v-if="commodity == 'Naphtha'">${{ value }}/bbl</p>
        </div>
        <img :src="icons[trend]" alt="">
    </div>
</template>

<script>
export default {
    props: {
        trend: String,
        title: String,
        value: String,
        commodity: String
    },
    data(){
        return {
            icons: {
                deltaUp: require('@/assets/img/delta-up.svg'),
                deltaDown: require('@/assets/img/delta-down.svg'),
            }
        }
    }
}
</script>

<style scoped>
.card{
    background-color: #EEEEEE;
}
</style>